import React from "react";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import "./pages.css";

const CookiesPolicy = (props) => {
  return (
    <Layout {...props}>
      <Seo title="3air Cookies Policy" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle header="Cookies Policy" />
      </Stack>
      <Stack>
        <Main>
          <h2>Cookies policy</h2>
          <p>
            Our Services uses cookies. By accessing our Services, we will inform
            you, through a pop-up banner, of our use of cookies.
          </p>
          <ol>
            <li>
              <strong>About cookies</strong>
              <p>
                Cookies are files, often with unique identifiers, that web
                servers send to Internet browsers and can then be sent back to
                the server each time the browser requests a page from the
                server.
              </p>
              <p>
                Web servers use cookies to identify and track users while
                browsing the different pages of Services, as well as to identify
                users returning to Services.
              </p>
              <p>
                Cookies can be “persistent” cookies or “session cookies”. A
                persistent cookie consists of a text file sent by a web server
                to an Internet browser, which is stored by the browser and
                remains valid until the defined expiration date (unless the user
                deletes it before the expiration date). On the other hand, a
                session cookie expires at the end of the user's session, when
                the Internet browser is closed.
              </p>
            </li>
            <li>
              <strong>Cookies from the Services</strong>

              <p>
                On our Services, including our Website, and mobile applications,
                we use session and persistent cookies.
              </p>
              <p>We will send you the following cookies:</p>
              <ol type="a">
                <li>Session Cookies</li>
                <li>
                  Google Analytics: This cookie allows us to identify unique
                  users, unique sessions, regulate the rate of requests and
                  store information about user sessions and campaigns.
                </li>
              </ol>
              <p>
                Cookie 1 is a session cookie, while Cookie 2 is a persistent
                cookie.
              </p>
            </li>

            <li>
              <strong>How we use cookies</strong>

              <p>
                Cookies do not contain personally identifiable information, but
                we have the possibility of linking the Personal Data we store
                about you with the information obtained and stored from cookies.
              </p>
              <p>
                We use the information we obtain from the use of our cookies for
                the following purposes:
              </p>
              <ol type="a">
                <li>
                  Recognize your computer when you access and/or use our
                  Services.
                </li>
                <li>Improve the usability of the Services.</li>
                <li>Analyze the use of our Services.</li>
                <li>Manage the Services.</li>
                <li>Third party cookies</li>
              </ol>
              <p>
                When you use the Services, you may also be sent third-party
                cookies.
              </p>
              <p>
                Our service providers can send you cookies. They use the
                information they obtain through their cookies for the following
                purposes:
              </p>
              <ol type="a">
                <li>Track your browser on different Services.</li>
                <li>Create a profile of your Internet browsing.</li>
                <li>Select specific ads that may be of interest.</li>
              </ol>
            </li>
            <li>
              <strong>Deletion and blocking of cookies</strong>

              <p>
                You can, at any time, restrict, block, or delete cookies from
                the Services. To do this, you must modify the configuration of
                your browser regarding the use of cookies through the
                “Preferences”, “Options” or “Tools” menu (the name of the menus
                or the procedure to access the cookie options vary depending on
                the browser used). Most browsers allow you to refuse to accept
                cookies and to delete cookies. The methods for doing so vary
                from browser to browser, and from version to version. You can
                however obtain up-to-date information about blocking and
                deleting cookies via these links:
              </p>
              <ul>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.google.com/chrome/answer/95647"
                  >
                    https://support.google.com/chrome/answer/95647
                  </a>{" "}
                  (Chrome);
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-Services-preferences"
                  >
                    https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-Services-preferences
                  </a>{" "}
                  (Firefox);
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.opera.com/en/latest/security-and-privacy/"
                  >
                    https://help.opera.com/en/latest/security-and-privacy/
                  </a>{" "}
                  (Opera);
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  >
                    https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                  </a>{" "}
                  (Internet Explorer);
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-Services-data-sfri11471/mac"
                  >
                    https://support.apple.com/en-gb/guide/safari/manage-cookies-and-Services-data-sfri11471/mac
                  </a>{" "}
                  (Safari); and
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  >
                    https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                  </a>{" "}
                  (Edge).
                </li>
              </ul>
            </li>
          </ol>
        </Main>
      </Stack>
    </Layout>
  );
};

export default CookiesPolicy;
